<template>
  <section id="Counseling">
     <v-sheet
    :height="$vuetify.breakpoint.smAndUp ? '50vh' : '75vh'"
    color="primary"
    tile
  >
    <!-- <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .50)"
      height="100%"
      position="top"
    > -->
      <v-container
        fill-height
        fluid
        class="py-0"
      >
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" align="center" class="ml-4">
            <h1
              class="mb-2 white--text mt-6 big-text text-uppercase">Feil Counseling</h1>
              <v-divider class="mb-4" color="white" width="200"> </v-divider>
            <p
              class="white--text mb-0" :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 1.2em': ''">With over 40 years of fulltime professional counseling experience, Doug has worked with a wide range of issues. <br>He works with adults providing individual, couples and adult family counseling. He also can provide counseling from a Christian perspective if so desired.</p>
          </v-col>
        </v-row>
      </v-container>
    <!-- </v-img> -->
  </v-sheet>
    <v-container fluid>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" offset-md="2">
          <v-sheet>
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3 ">
                <v-img style="border-radius: 10px;" src='/static/asian-couple-argument.jpg' alt="couple having argument"/>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9 ">
            <v-row>
               <v-col align="center" cols="12">
                  <h2>Couple and Marriage Counseling</h2>
               <v-divider class="mt-3" ></v-divider>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                  <p>With couples, Doug focuses on using CBT (Cognitive Behavioral Therapy) and SFT (Solution Focused Treatments), helping resolve issues and strengthen their relationship. He will also provide a variety of educational resources to support their growth process.</p>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Areas of specialty here include:</h3>
                      <ul>
                        <li v-for="(item, index) in areasofspecialtycouples" :key="index">{{item}}</li>
                    </ul>
               </v-col>
            </v-row>
                </v-col>
            </v-row>
          </v-sheet>
        </v-col>
         <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" offset-md="2">
           <v-sheet>
             <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3 ">
                <v-img style="border-radius: 10px;" src='/static/womanpraying.png' alt="woman praying"/>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9 ">
            <v-row>
               <v-col align="center" cols="12">
                  <h2>Individual Counseling</h2>
               <v-divider class="mt-3" ></v-divider>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                  <p>Doug uses a variety of therapeutic techniques including CBT, SFT and other strategies to aid in achieving meaningful outcomes. He believes in the use of homework with each session since real growth occurs in your life day to day.</p>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Areas of specialty include:</h3>
                      <ul>
                        <li v-for="(item, index) in areasofspecialtysingels" :key="index">{{item}}</li>
                    </ul>
               </v-col>
            </v-row>
              </v-col>
                  <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Missionary and Pastors</h3>
                      <p>Having been involved in Christian ministry for over 40 years, areas of specialty include pastors, missionaries, Christian leaders, and working with Christian organizations.</p>       
                        <v-btn
                        to="ministry"
                        class="pl-0"
                        color="primary"
                        text
                      >Learn more</v-btn>
               </v-col>
             </v-row>
          </v-sheet>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" offset-md="2">
          <v-row>
            <v-col cols="12">
              <h2 style="color: grey;" class="text-uppercase">Related Blog Articles</h2>
            </v-col>
            <v-col v-for="(article, index) in Articles" :key="index" cols="2">
              <v-sheet color="white" elevation="1" class="rounded-xl">
                <v-row>
                  <v-col align="center" cols="12" class="mt-0 pt-0">
                  <v-img style="border-top-left-radius: 25px; border-top-right-radius: 25px;" :src="article.img"></v-img>
                  </v-col>
                  <v-col align="center" cols="12" class="pt-0">
                      <h3>{{article.title}}</h3>
                  <v-divider class="mt-3" ></v-divider>
                  </v-col>
                  <v-col cols="12" class="ml-2 px-8">
                      <p>{{article.description}}</p>
                      <v-btn class="pl-0" color="primary" :onclick="`location.href='${article.url}';`" text>Read More</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row
        class="my-5 px-5"
      >
        <v-col
          class="px-0"
          cols="12"
          md="8"
        >
          <v-card
            flat
            color="transparent"
          >
            <v-card-title class="big-text">You are not alone.</v-card-title>

            <v-card-text class="pr-16">
              Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an. Pro iriure alienum incorrupte ex, cu diceret moderatius mei. Te corrumpit posidonium sit, in cum nonumy utamur. Usu impedit patrioque vituperatoribus ei, cu voluptua nominati philosophia mel. Sea at legendos percipitur, nulla dicant convenire ne duo. Veniam epicuri vis cu, ea pri alii ceteros fierent.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              src="/static/asian-couple-cooking-in-the-kitchen.jpg"
              height="250"
            />
          </v-card>
        </v-col>
      </v-row> -->
      
    </v-container>
    <AccentBar v-if="$vuetify.breakpoint.mdAndUp" />
    <!-- <MidImage :bartype="'happy'"/> -->
    <ContactBar />
  </section>
</template>

<script>
import blogArticles from '../blogarticles.json';

  export default {
    metaInfo: {
      title: 'Feil Individual and Couples Counseling Services',
      meta: [
        { name: 'description', content: 'We offer both couples and individual counseling via Teletherapy or in person sessions.' }
      ]
    },

    components: {
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial'),
      GetAQuote: () => import('@/components/GetAQuote'),
      ContactBar: () => import('@/components/ContactBar'),
      MidImage: () => import('@/components/MidImage'),
      AccentBar: () => import('@/components/AccentBar')
    },

    data: () => ({
      testimonial: {
        blurb: 'With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.',
        person: 'Jay Oakrson',
        title: 'CEO Abocado'
      },
      areasofspecialtycouples: ["Communication", "Healing after affairs", "Reconciliation", "Marriage enrichment", "Premarital counseling", "Recovery after divorce"],
      areasofspecialtysingels: ["Anxiety", "Personality disorders", "Sexual addiction", "Depression", "Abuse and trauma", "Dissociative disorders", "Men's issues", "Christian counseling", "Self-esteem", "Self-care and growth"]
    }),
    computed:{
      Articles(){
        let articles = blogArticles.filter((item)=>{
          return item.page == 'counseling'
        })

        return articles;
      }
    }
  }
</script>
<style scoped>
.big-text {
    font-size: 3em;
}
.midbar-text {
    font-size: 1.5em;
}
</style>
